import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import { Container, Row, Column, Stack } from '../../components/Layout'
import Header from '../../components/Header'
import SectionCard from '../../components/SectionCard'
import CaseStudyDetails from '../../components/CaseStudyDetails'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Footer from '../../components/Footer'

export const frontmatter = {
  title: 'Meal List',
  description:
    'An easy way to keep you in control of  your healthy lifestyle. Meal planner + shopping list blissfully combined. ',
  thumbnail: '../../images/work/meal-list/thumbnail.png',
  order: 2,
}

const MealList = ({ path, pageContext, data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.siteInfo.siteMetadata.title}</title>
      <link rel="canonical" href={data.siteInfo.siteMetadata.url} />
      <meta
        name="description"
        content={data.siteInfo.siteMetadata.description}
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
    </Helmet>
    <Header title={frontmatter.title} intro={frontmatter.description} secondary>
      <Img
        fluid={data.asset01.childImageSharp.fluid}
        alt="meal plan and shopping list"
      />
    </Header>
    <Container>
      <Row verticalSpacing={[2, 0]}>
        <CaseStudyDetails
          items={[
            { key: 'Project Length (Conceptual)', value: '12 hours' },
            { key: 'Primary Design Tool', value: 'Figma' },
            { key: 'My Role', value: 'Designer/Creator' },
          ]}
        />
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Stack space={0.5}>
            <Text>Problem</Text>
            <Text>
              One of the most difficult parts of eating healthy foods is
              planning out meals that are nourishing. The added challenge of
              remembering what each piece of produce was for lends itself to
              forgotten vegetables and fruits pushed to the back of the fridge
              and money wasted.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Stack space={0.5}>
            <Text>Solution</Text>
            <Text>
              Choose from user-uploaded recipes to plan out a week of meals,
              utilize a generated shopping list, and use all your beautiful
              produce before it goes bad in the fridge. Having a plan for
              plant-foods increases the liklihood you’ll use them before they
              expire.
            </Text>
          </Stack>
        </Column>
      </Row>
    </Container>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Project Flow
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Text>
          Starting out a project with a clear road map forces me to thoroughly
          think through the project before jumping into design. It also assists
          in desiging in chronological order and avoids blind spots from jumping
          too much throughout the project.
        </Text>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={12}>
        <Img
          fluid={data.asset02.childImageSharp.fluid}
          alt="project flow map"
        />
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Low Fidelity Designs
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Text>
          I find that starting with low-fidelity makes the transtion to
          high-fidelity design incredibly smooth. I usually make some rough
          mockups in Figma, but occassionally I will sketch out a layout or
          iterations of a specific element. The initial layout and structure of
          the project was nearly unchanged between low and high fidelity
          renderings.
        </Text>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={12}>
        <Img
          fluid={data.asset03.childImageSharp.fluid}
          alt="low fidelity designs"
        />
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={4}>
        <Img
          fluid={data.asset04.childImageSharp.fluid}
          alt="navigation, meal list home tab"
        />
      </Column>
      <Column offset={7} span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Navigation
        </Heading>
        <Stack verticalSpacing={[2, 0]} space={1}>
          <Text>Screen: Home/Discover</Text>
          <Text>
            In the home tab, users are able to scroll through recipes and use it
            as a “discovery” section. The bottom tab bar takes user from home,
            to saved recipes, to meal plan, to more options.
          </Text>
          <Text>
            Above the list of recipes is a horizontal sliding navigation bar to
            allow users to easily choose which type of meal they may want to
            make. The selected “meal type” is bold, blue, and underscored by a
            small circle to show which catergory a user is currently in.
          </Text>
          <Text>
            They also have easy access to their own profile via the avatar at
            the top right of their screen.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Primary Actions
        </Heading>
        <Stack verticalSpacing={[2, 0]} space={1}>
          <Text>Screen: Recipe Detail</Text>
          <Text>
            Users are carefully guided throughout the app. Each screen has one
            clear primary action and may also have secondary or tirtiary actions
            to accompany it. Interactive elements and primary actions are
            indicated by a blue background or a blue stroke for buttons.
          </Text>
          <Text>
            In this case, users are encouraged to add a meal to their meal plan,
            thus creating a shopping list for them, but they can also choose to
            simpy save the recipe if they are not ready to make it but want the
            recipe in their arsenal.
          </Text>
          <Text>
            All ingredients are listed right at the top for convenience and
            detailed directions are listed directly below. When looking for
            recipes online, users typically have to scroll through a long blog
            post of someone rambling on about their life story when people
            really just want to understand how to make the meal.
          </Text>
        </Stack>
      </Column>
      <Column offset={9} span={4}>
        <Img
          fluid={data.asset05.childImageSharp.fluid}
          alt="Primary Actions, recipe detail view"
        ></Img>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={4}>
        <Img
          fluid={data.asset06.childImageSharp.fluid}
          alt="Interaction, shopping list tab"
        ></Img>
      </Column>
      <Column offset={7} span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Interaction
        </Heading>
        <Stack verticalSpacing={[2, 0]} space={1}>
          <Text>Screen: Shopping List</Text>
          <Text>
            A shopping list is automatically generating based on recipes users
            have saved to their meal plan.
          </Text>
          <Text>
            Major points of interaction are using the toggle to switch back and
            forth between their meal plan and generataed shopping list, checking
            items off the shopping list, minimzing and maximizing grocery
            categories, and adding item to the list that don’t apply to recipes
            saved in the app. The “Add Item” button is a cool gray so as not to
            compete with the toggle at the top of the screen.
          </Text>
          <Text>
            Interactive elements are clear and easy for the user to understand
            because they follow an expected pattern of blue hues to indicate
            what they can manipulate.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={12}>
        <Img
          fluid={data.asset07.childImageSharp.fluid}
          alt="meal list overview"
        />
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as={'h2'}>
          Color Palette
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={12}>
        <Img
          fluid={data.asset08.childImageSharp.fluid}
          alt="color palette"
        ></Img>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Stack space={1}>
          <Text>
            My goal for the primary brand color was to surpass a score of 4.0.
            The primary brand color is used in various interactive elements,
            most notably buttons. The boldness of the primary button really
            showcases the delicate balance of periwinkle and blue in a bold way.
          </Text>
          <Text>
            Instead of using plain black text, I opted for using a dark blue to
            compliment the periwinkle found scattered throughout the interactive
            elements of the app. The dark blue helps tie all the different
            elements on the screen together.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Strengths & Challenges
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[4, 6]}>
      <Column span={5}>
        <Heading level={2} as="h2">
          Strengths
        </Heading>
        <ul className="list-disc list-outside">
          <li>Visually stunning and overall clean feel</li>
          <li>
            Intuitive flow patterns and clear indications of interactive
            elements
          </li>
          <li>Potential to be a genuinely helpful tool for users</li>
        </ul>
      </Column>
      <Column span={5}>
        <Heading level={2} as="h2">
          Challenges
        </Heading>
        <ul className="list-disc list-outside">
          <li>Main brand color is just shy of passing AA standard</li>
          <li>
            User-uploaded content could be misused and irrelevant to the mission
            of the app
          </li>
          <li>
            Tab bar icons could be designed in a clearer way to avoid needing
            icon labels
          </li>
        </ul>
      </Column>
    </Row>
  </>
)

export const query = graphql`
  query MealsQuery {
    siteInfo: site {
      siteMetadata {
        name
        title
        description
        url
      }
    }

    asset01: file(relativePath: { eq: "work/meal-list/asset-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset02: file(relativePath: { eq: "work/meal-list/asset-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset03: file(relativePath: { eq: "work/meal-list/asset-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset04: file(relativePath: { eq: "work/meal-list/asset-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset02: file(relativePath: { eq: "work/meal-list/asset-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset05: file(relativePath: { eq: "work/meal-list/asset-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset06: file(relativePath: { eq: "work/meal-list/asset-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset07: file(relativePath: { eq: "work/meal-list/asset-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset08: file(relativePath: { eq: "work/meal-list/asset-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`

export default MealList
